// Device breakpoints
$breakpoint-sm-min: 576px !default;
$breakpoint-md-min: 768px !default;
$breakpoint-lg-min: 992px !default;
$breakpoint-xl-min: 1200px !default;

$breakpoint-xs-max: ($breakpoint-sm-min - 1px) !default;
$breakpoint-sm-max: ($breakpoint-md-min - 1px) !default;
$breakpoint-md-max: ($breakpoint-lg-min - 1px) !default;
$breakpoint-lg-max: ($breakpoint-xl-min - 1px) !default;

// Mixins

@mixin breakpoint-xs-max() {
  @media (max-width: $breakpoint-xs-max) {
    @content;
  }
}

@mixin breakpoint-sm-max() {
  @media (max-width: $breakpoint-sm-max) {
    @content;
  }
}

@mixin breakpoint-md-max() {
  @media (max-width: $breakpoint-md-max) {
    @content;
  }
}

@mixin breakpoint-lg-max() {
  @media (max-width: $breakpoint-lg-max) {
    @content;
  }
}

@mixin breakpoint-sm-min() {
  @media (min-width: $breakpoint-sm-min) {
    @content;
  }
}

@mixin breakpoint-md-min() {
  @media (min-width: $breakpoint-md-min) {
    @content;
  }
}

@mixin breakpoint-lg-min() {
  @media (min-width: $breakpoint-lg-min) {
    @content;
  }
}

@mixin breakpoint-xl-min() {
  @media (min-width: $breakpoint-xl-min) {
    @content;
  }
}